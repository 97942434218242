<template>
  <v-list color="white" class="rounded">
    <v-list-item :href="phoneLink" two-line>
      <v-list-item-icon class="my-0 align-self-center"><v-icon>$vuetify.icons.headphones</v-icon></v-list-item-icon>

      <v-list-item-content>
        <v-list-item-subtitle class="caption">{{ $vuetify.lang.t('Contact our support') }}</v-list-item-subtitle>
        <v-list-item-title class="text-h6 black--text">{{ phone }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    phone: {
      type: String,
      default: process.env.VUE_APP_SUPPORT_PHONE,
    },
  },

  computed: {
    phoneLink() {
      const link = this.phone.replace(/[\s\+]/g, '');
      return `tel:${link}`;
    },
  },
};
</script>
